import React, { useEffect, useState } from 'react'
import qs from 'qs'

import { authedGet, authedPost, getSessionId } from '../lib/http'
import { CandidateProforma, JwtUser, ProformaRequest } from '../types/types'
import { proformaRequestToForm } from './mapper'

import './MyProformas.css'

const formatDateTime = (dateTime?: string) => {
  return dateTime ? new Date(dateTime).toLocaleTimeString() : ''
}

export const getApiUrl = () => {
  const isLocal = window.location.host.indexOf('localhost') !== -1
  return isLocal ? 'http://localhost:8001/api' : '/api'
}

export const MyProformas = () => {
  const [myProformaRequests, setMyProformaRequests] = useState(
    [] as ProformaRequest[]
  )
  const [, setCandidateProformas] = useState([] as CandidateProforma[])
  const [nextProformaRequests, setNextProformaRequests] = useState(
    [] as ProformaRequest[]
  )
  const [user, setUser] = useState(undefined as JwtUser | undefined)

  const fetchUser = async () => {
    try {
      const result = await authedGet('user')
      const json = await result.json()
      setUser(json as JwtUser)
      console.log(json)
    } catch (e) {}
  }

  useEffect(() => {
    fetchUser()
  }, [])

  const loadMyProformas = async () => {
    const result = await authedGet(`proformas/mine`)
    const json = await result.json()
    setMyProformaRequests(json.proformaRequests)
    setCandidateProformas(json.candidateProformas)
  }

  const loadProformaRequests = async () => {
    const result = await authedGet(`proformas/requests`)
    const json = await result.json()
    setNextProformaRequests(json)
  }

  const restartApp = async () => {
    if (
      window.confirm(
        `Are you sure you want to restart the application? This will interrupt any running proforma processes.`
      )
    ) {
      await authedPost(`proformas/restart`, {})
    }
  }

  useEffect(() => {
    loadMyProformas()
    loadProformaRequests()
  }, [])

  return (
    <div>
      <div className="dashboard-form proforma-left">
        <div className="popup-section-title">Proforma status</div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <div>
              <b>Next 3 proformas:</b>
            </div>
            {nextProformaRequests.map((request) => (
              <div key={request.ID}>
                {request.CreatedByEmail} at {formatDateTime(request.CreatedAt)}{' '}
                ({request.State})
              </div>
            ))}
          </div>
          <div style={{ flex: 1 }}>
            <div className="restart-text">
              Restarting the application will interrupt any running proforma
              processes. Only use this button if you are sure it is stuck
              <a href="/leaderboard">.</a>
            </div>
            <div className="form-line">
              <button
                className="button-primary button-medium"
                onClick={() => restartApp()}
              >
                Restart
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-form">
        <div className="popup-section-title">Proforma requests</div>
        <table width="100%" className="table" cellSpacing={0}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Status</th>
              <th>Type</th>
              <th>Client</th>
              <th>Work permit</th>
              <th>Thirty percent</th>
              <th>Hours/week</th>
              <th>Work-home distance</th>
              <th>Cost price/hour</th>
              <th>Gross wage/hour</th>
              <th>Net salary/month</th>
            </tr>
          </thead>
          <tbody>
            {myProformaRequests.map((proformaRequest: ProformaRequest) => {
              let path
              try {
                const form = proformaRequestToForm(proformaRequest)
                path = qs.stringify(form)
              } catch {}
              return (
                <tr key={proformaRequest.ID}>
                  <td>
                    {path ? (
                      <a href={`/?${path}`}>{proformaRequest.ID}</a>
                    ) : (
                      proformaRequest.ID
                    )}
                  </td>
                  <td>{proformaRequest.State}</td>
                  <td>{proformaRequest.Type}</td>
                  <td>{proformaRequest.Client}</td>
                  <td>{proformaRequest.WorkPermit ? 'Yes' : 'No'}</td>
                  <td>{proformaRequest.Ruling30 ? 'Yes' : 'No'}</td>
                  <td>{proformaRequest.HoursPerWeek}</td>
                  <td>{proformaRequest.TravelDistanceKm}</td>
                  <td>{proformaRequest.CostPricePerHour}</td>
                  <td>{proformaRequest.GrossWagePerHour}</td>
                  <td>{proformaRequest.NetSalaryPerMonth}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
