import React, { useEffect, useState } from 'react'
import { Route } from 'wouter'

import './App.css'
import './lib/ui/Tabs.css'
import './lib/ui/ui.css'
import './lib/ui/Form.css'

import { authedGet } from './lib/http'
import { JwtUser } from './types/types'
import { Payrolled } from './components/Payrolled'
import { MyProformas } from './components/MyProformas'
import { NavigationTab } from './components/NavigationTab'
import { SelfEmployed } from './components/SelfEmployed'
import { Employed } from './components/Employed'
import { Permanent } from './components/Permanent'
import { Leaderboard } from './components/Leaderboard'

const INTRANET_URL = 'https://intranet.magno-it.nl'
const REDIRECT_URL = 'https://proformas.magno-it.nl'
const isLocal = window.location.host.indexOf('localhost') !== -1

function App() {
  const [user, setUser] = useState(undefined as JwtUser | undefined)
  const [error, setError] = useState(undefined as string | undefined)

  const fetchUser = async () => {
    try {
      const result = await authedGet('user')
      const json = await result.json()
      if (result.status !== 200) {
        if (json.error === 'invalid token' && !isLocal) {
          window.location.href = INTRANET_URL + '/?ReturnUrl=' + REDIRECT_URL
          return
        }
        return setError(json.error)
      }
      setUser(json as JwtUser)
    } catch (e) {
      console.error(e)
      setError('Cannot connect to API')
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  if (error) {
    const description =
      error === 'invalid token' &&
      'You are not currently authenticated. Please try logging into the Magno Intranet again. If you still have problems let back office know.'
    return (
      <div>
        <div>Error loading application: '{error.toUpperCase()}'</div>
        <div>{description}</div>
      </div>
    )
  }

  if (!user) {
    return <div />
  }

  return (
    <div className="lst-container cl-container dashboard-background">
      <div className="tabs only-bottom container-white">
        <NavigationTab href="/">Payrolled</NavigationTab>
        <NavigationTab href="/self-employed">Self-Employed</NavigationTab>
        <NavigationTab href="/employed">Employed</NavigationTab>
        <NavigationTab href="/permanent">Permanent</NavigationTab>
        <NavigationTab href="/my-proformas">My proformas</NavigationTab>
      </div>
      <Route path="/" component={Payrolled} />
      <Route path="/self-employed" component={SelfEmployed} />
      <Route path="/employed" component={Employed} />
      <Route path="/permanent" component={Permanent} />
      <Route path="/my-proformas" component={MyProformas} />
      <Route path="/leaderboard" component={Leaderboard} />
    </div>
  )
}

export default App
